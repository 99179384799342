import React from 'react'
import styled from '@emotion/styled'

import { mediaQueries } from '../theme'
import Container from '../components/shared/container'
import Layout from '../components/layout'
import PageMetadata from '../components/page-metadata'
import {
  Article,
  ArticleHeading,
  Heading,
  P,
  Ul,
  Ol,
} from '../components/shared/type'

const InnerContainer = styled(Container)`
  margin-bottom: ${p => p.theme.space[18]};

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[29]};
  }
`

const Section = styled.section`
  max-width: 48.75rem;
`

const AntiSpamPolicyPage = ({ location }) => (
  <Layout location={location}>
    <PageMetadata
      title="Getform's Anti-Spam Policy: Ensuring Permission-Based Email Marketing"
      description="Learn about Getform's anti-spam policy and find out how to comply with permission-based email marketing principles."
      keywords="Anti-Spam Policy"
    />
    <InnerContainer>
      <Section>
        <Heading>Anti-Spam policy</Heading>
        <Article>
          <P>
            At Getwebcraft Limited, we believe email marketing should only be
            done with the recipient's consent. We also have zero tolerance for
            spam.
          </P>
          <P>
            To prevent any form of unsolicited email marketing, we require our
            customers to comply with our Anti-Spam policy. Failure to do so may
            result in the account suspension.
          </P>
        </Article>
        <Article>
          <ArticleHeading>What is considered spam?</ArticleHeading>
          <P>
            Spam is any unsolicited, unwanted, or irrelevant email message sent
            in bulk to recipients who have not given their explicit consent to
            receive such messages.
          </P>
          <P>Examples of spam include:</P>
          <Ul>
            <li>
              marketing communications sent without the recipient's permission,
            </li>
            <li>messages containing false or misleading information,</li>
            <li>messages designed to deceive or defraud the recipient,</li>
            <li>
              phishing emails disguised as legitimate emails to obtain personal
              information,
            </li>
            <li>emails containing viruses or malware,</li>
            <li>chain emails forwarded without consent,</li>
            <li>emails without an opt-out or unsubscribe link.</li>
          </Ul>
          <P>
            Getwebcraft does not condone any form of spamming and takes measures
            to prevent our email marketing software from being used for spamming
            purposes.
          </P>
        </Article>
        <Article>
          <ArticleHeading>
            Permission-based email marketing principles
          </ArticleHeading>
          <P>
            To comply with our Anti-Spam Policy, our customers must adhere to
            permission-based email marketing principles, including the
            following:
          </P>
          <Ol counter="number">
            <li>
              Customers can only send marketing emails to those who have
              explicitly given their consent to receive such emails.
            </li>
            <li>
              If website forms are used to collect emails, and double opt-in
              isn’t activated, the forms must collect explicit consent from new
              subscribers via a mandatory checkbox.
            </li>
            <li>
              All marketing emails sent using Getsitecontrol must include an
              easily visible and functional unsubscribe link.
            </li>
            <li>
              Customers must comply with all applicable anti-spam laws,
              including the CAN-SPAM Act in the United States, the CASL in
              Canada, and the GDPR in the European Union.
            </li>
          </Ol>
        </Article>
        <Article>
          <ArticleHeading>
            Account suspension and termination rules
          </ArticleHeading>
          <P>
            Getwebcraft monitors all customer accounts to ensure compliance with
            our Anti-Spam Policy. We reserve the right to suspend accounts if
            there are any indications of spam activity. Such indications
            include:
          </P>
          <Ul>
            <li>Spam complaint rate over 0.2%</li>
            <li>Bounce rate over 5%</li>
            <li>Unsubscribe rate over 1%</li>
          </Ul>
          <P>
            Customers violating our policy may have their accounts suspended or
            terminated.
          </P>
          <P>
            We take our Anti-Spam Policy very seriously and expect our customers
            to do the same. By following our guidelines, you can ensure that
            your email marketing practices are compliant and avoid account
            suspension or termination.
          </P>
        </Article>
      </Section>
    </InnerContainer>
  </Layout>
)

export default AntiSpamPolicyPage
